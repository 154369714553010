import { initializeApp } from 'firebase/app'
import { getMessaging, Messaging } from 'firebase/messaging' // Added 'Messaging' type

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
}

// Validate Config
const isConfigValid = Object.values(firebaseConfig).every(Boolean)

// Safe Initialization
let app: ReturnType<typeof initializeApp> | null = null
let messaging: Messaging | null = null

if (isConfigValid) {
  try {
    app = initializeApp(firebaseConfig)
    messaging = typeof window !== 'undefined' ? getMessaging(app) : null
    console.log('Firebase initialized successfully.')
  } catch (error) {
    console.error('Firebase initialization failed:', error)
  }
} else {
  console.warn('⚠️ Missing Firebase config values. Firebase is disabled.')
}

export { app, messaging }
