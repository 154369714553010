// components/FcmHandler.jsx
'use client'
import { useEffect } from 'react'
import useFcm from '@/hooks/useFcm'

type FcmHandlerProps = {
  userLoggedIn: boolean
}

const FcmHandler: React.FC<FcmHandlerProps> = ({ userLoggedIn }) => {
  const { fcmToken, message } = useFcm({ userLoggedIn })

  useEffect(() => {
    if (fcmToken) {
      console.log('FCM token:', fcmToken)
    }
  }, [fcmToken])

  useEffect(() => {
    if (message) {
      console.log('New FCM message:', message)
    }
  }, [message])

  return null
}

export default FcmHandler
