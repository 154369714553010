// hooks/useFcm.ts or useFcm.js

import { useEffect, useState } from 'react'
import { messaging } from '@/lib/firebase/firebase'
import { getToken, onMessage, type MessagePayload } from 'firebase/messaging'
import { useFlags } from 'launchdarkly-react-client-sdk'

type UseFcmProps = {
  userLoggedIn: boolean
}

export default function useFcm({ userLoggedIn }: UseFcmProps) {
  const [fcmToken, setFcmToken] = useState('')
  const [message, setMessage] = useState<MessagePayload | null>(null)
  const { enableNotifications } = useFlags()

  useEffect(() => {
    // Ensure we're in the browser and messaging is available.
    if (
      typeof window !== 'undefined' &&
      messaging &&
      userLoggedIn &&
      enableNotifications
    ) {
      // First, explicitly register the service worker.
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered:', registration)
          // Request notification permission after service worker is registered.
          Notification.requestPermission().then((permission) => {
            console.log('Notification permission:', permission)
            if (permission === 'granted' && messaging) {
              // Now pass the registration to getToken explicitly.
              getToken(messaging, {
                vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
                serviceWorkerRegistration: registration,
              })
                .then((currentToken) => {
                  if (currentToken) {
                    setFcmToken(currentToken)
                    console.log(
                      'FCM token successfully retrieved:',
                      currentToken
                    )
                  } else {
                    console.log('No registration token available.')
                  }
                })
                .catch((err) => {
                  console.error('Error retrieving token:', err)
                })

              // Listen for foreground messages.
              onMessage(messaging, (payload) => {
                console.log('Foreground message received:', payload)
                setMessage(payload as MessagePayload)
              })
            } else {
              console.log('Notification permission not granted.')
            }
          })
        })
        .catch((err) => {
          console.error('Service Worker registration failed:', err)
        })
    }
  }, [userLoggedIn, enableNotifications])

  return { fcmToken, message }
}
